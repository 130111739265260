<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  data: () => ({

  }),
}
</script>


<style>
@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  overflow: hidden;
}

body {
  font-family: 'worksans';
  font-weight: 400;
  background: #EFF2F4;
}

.global-tabs {
  display: flex;
  padding: 0 1em;
}

.global-tab {
  padding: 0.8em 1em;
  cursor: pointer;
  font-weight: 400;
  color: #788991;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.global-tab.active-tab {
  border-bottom: 2px solid #B1322F;
  font-weight: 600;
  color: #383838;
}

.seller-status.ACTIVE {
  background: #CDFFE7;
  color: #47A047;
}

.seller-status.PENDING {
  background: #F0E4FF;
  color: #9A62E1;
}

.seller-status.INREVIEW {
  background: #FDE4FF;
  color: #E155C2;
}

.seller-status.PRE_ACTIVATION {
  background: #D6FAF3;
  color: #4EBEAA;
}

.seller-status.REJECTED {
  background: #FFE9E9;
  color: #DE4340;
}

.seller-status-text.ACTIVE {
  color: #47A047;
}

.seller-status-text.PENDING {
  color: #9A62E1;
}

.seller-status-text.INREVIEW {
  color: #E155C2;
}

.seller-status-text.PRE_ACTIVATION {
  color: #4EBEAA;
}

.seller-status-text.REJECTED,
.seller-status-text.BLOCKED {
  color: #DE4340;
}

.data-progress-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>